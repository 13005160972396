import React, { useContext, useEffect, useRef, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import {CallMissedOutgoing } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { purple } from '@material-ui/core/colors';
import { i18n } from "../../translate/i18n";
import TransferTicketModal from "../TransferTicketModal";


const TicketTransferButton = ({ ticket, menuOpen, handleClose, anchorEl }) => {
	//const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const isMounted = useRef(true);
	//const { user } = useContext(AuthContext);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);





	const handleOpenTransferModal = e => {
		setTransferTicketModalOpen(true);
		handleClose();
	};

	const handleCloseTransferTicketModal = () => {
		if (isMounted.current) {
			setTransferTicketModalOpen(false);
		}
	};

	return (
		<>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={handleClose}
			>
				<MenuItem onClick={handleOpenTransferModal}>
					{i18n.t("ticketOptionsMenu.transfer")}
				</MenuItem>
			</Menu>
            <span title={i18n.t("messagesList.header.buttons.transfer")}> 
				<IconButton
                  
                    size="small"
                    onClick={handleOpenTransferModal}
                    variant="contained"
                	>
					<CallMissedOutgoing style={{  color: '#152d70', fontSize: 33 }} />  
                </IconButton>
            </span>


			<TransferTicketModal
				modalOpen={transferTicketModalOpen}
				onClose={handleCloseTransferTicketModal}
				ticketid={ticket.id}
			/>
		</>
	);
};

export default TicketTransferButton;
