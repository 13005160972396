
import React, { useState, useEffect, useReducer } from "react";
import ReactDOM from "react-dom";
import Board, { moveCard } from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";

import { makeStyles } from "@material-ui/core/styles";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import {blue, purple, red, green, teal, orange, amber} from '@material-ui/core/colors';

import openSocket from "../../services/socket-io";
import api from "../../services/api";
import Swal from 'sweetalert2'

import {
    Grid,
	Button,
	TableBody,
	TableRow,
	TableCell,
	IconButton,
	Table,
	TableHead,
	Paper,
	Tooltip,
	Typography,
	CircularProgress,
} from "@material-ui/core";

import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import InfoIcon from '@material-ui/icons/Info';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import FunctionsIcon from '@material-ui/icons/Functions';
import FlashOnIcon from '@material-ui/icons/FlashOn';

import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

// Use your own styles to override the default styles
import "./styles.css";


const useStyles = makeStyles((theme) => ({
    cardHeaderRoot: {
        padding:"6px",
      },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  avatarPic:{
    width: "180px",
    height: "180px",
    borderRadius:"20px"
},
}));
  

const board = {
  columns: [
    {
      id: 1,
      title: "Aguardando",
      backgroundColor: "#fff",
      cards: [
        {
          id: 1,
          title: " ",
          description: (
               <div>
                    <Card style={{maxWidth: 345}}>
                        <CardHeader
                                    avatar={
                                    <Avatar aria-label="recipe" style={{backgroundColor: red[500],}}>
                                        RC
                                    </Avatar>
                                    }
                                    action={
                                    <IconButton aria-label="settings">
                                        <InfoIcon />
                                    </IconButton>
                                    }
                                    title="FGTS - R$ 50.000"
                                    subheader="2022-12-28"
                                />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                <div><MonetizationOnIcon style={{color:green[500]}}/> Valor: <span style={{fontWeight:700}}>R$ 50.000</span></div>
                                <div><EventIcon style={{color:purple[500]}}/> Fechamento: <span style={{fontWeight:700}}>2022-12-28</span></div>
                                <div><PersonIcon  style={{color:teal[500]}} />Responsável: <span style={{fontWeight:700}}>Ricardo Carvalho</span></div>
                                <div><FunctionsIcon  style={{color:orange[500]}} />Probabilidade: <span style={{fontWeight:700}}>10%</span></div>
                                <div><FlashOnIcon style={{color:blue[500]}} />Proxima Atividade: <span style={{fontWeight:700, color:red[500]}}>2022-12-20</span></div>
                            </Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                                <IconButton aria-label="add to favorites">
                                    <ThumbUpIcon style={{color:green[500]}} />
                                </IconButton>
                                <IconButton aria-label="share">
                                    <ThumbDownIcon style={{color:red[500]}} />
                                </IconButton>
                           
                        </CardActions>
                    </Card>
                </div> 
          )
        }
      ]
    },
    {
      id: 2,
      title: "Em Tratativa",
      cards: [
      ]
    },
    {
      id: 3,
      title: "Com Impedimento",
      cards: [
      ]
    }
  ]



};


const formatCard = (kanbanCard) => {
   console.log("kanbanCard?.user?.name", kanbanCard)
   const names=kanbanCard?.username.split(" ")
   const fstCap = names[0].substring(0, 1)
   const scdCap = names[1].substring(0, 1)


   const handleExtraInfo = async (card) =>{
    console.log("kanbanCard", card)
    let currBackend=`${window.location.protocol}//${window.location.hostname}`
    Swal.fire({

          title: 'Informação de Agendamento',   
          icon: "info",
          html: `${card.bookingTypeInfo} : ${card?.bookingId}<br />
                <br/><span style='font-weight:600;'>Contato : </span>${card?.name} - ${card?.contactNumber}
                <br/><span style='font-weight:600;'>Tarefa : </span>${card?.bookingName}
                <br/><span style='font-weight:600;'>Responsavel : </span>${card?.positionName}
                 <br><span style='font-weight:600;'>Descrição : </span>${card?.description},
                <br>
                <br><a target="_new" href="${currBackend}/newticket?phone=${card?.contactNumber}&text=Oi,%20estou%20confirmando seu ${card?.bookingName}" autofocus>Confirmar</a>
                `,
          confirmButtonText: 'Fechar'


      })
   }




   const handleClosingStatus = async (id, zero, status) =>{ 
    console.log("handleClosingStatus", id, zero, status)
    handleUpdateCard(id, zero, status)
   }

   const handleUpdateCard = async (id, from, to) =>{

    const kbData = {
      id,
      from,
      to
    }


    try {
      const { data } = await api.post(`/agenda/kanboard/${id}`,kbData);
      console.log("currBooking", data)

    if(from==0){
        Swal.fire({
            title: `Informação de alteração de status para "${to}"`, 
            icon: 'success',  
            html: `A sua tarefa entrou em processo de ${to} e será arquivada com esse status, 
            sendo removida da sua lista de tarefas no proximo refrescamento de tela.<br/><br/>Obrigado.`,
            confirmButtonText: 'Fechar'
          })
    }

    


    
    } catch (err) {
    console.log(err);
    }
  }

   const stringToColor = (string) => {
		if (string != undefined && string != '' ){
			let hash = 0;
			let i;
		  
			/* eslint-disable no-bitwise */
			for (i = 0; i < string.length; i += 1) {
			  hash = string.charCodeAt(i) + ((hash << 5) - hash);
			}
		  
			let color = '#';
		  
			for (i = 0; i < 3; i += 1) {
			  const value = (hash >> (i * 8)) & 0xff;
			  color += `00${value.toString(16)}`.slice(-2);
			}
			/* eslint-enable no-bitwise */
		  
			return color;
		}else{
			return "#ff3232";
		}

	}

	const stringAvatar = (name) => {
		let currInitials;

		if (name != undefined && name != '' ){
			if (/^\d+$/.test(name) || name == "") {
				currInitials = name.slice(-2);
			} else {
	
				let namesplit=name.split(' ');
	
				if (namesplit.length==1){
					currInitials = `${name.split(' ')[0][0]}${name.split(' ')[0][1]}`;
				}else if(namesplit.length>1){
					currInitials = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
				}
			}
	
			return {
				sx: {
					bgcolor: stringToColor(name),
				},
				children: currInitials,
			};
		}else{
			return {
				sx: {
					bgcolor: "#ff3232",
				},
				children: "⚠️",
			};
		}
		

	}

	function getContrastColor(hexColor){
		let r = parseInt(hexColor.substr(1, 2), 16); // Get red value
		let g = parseInt(hexColor.substr(3, 2), 16); // Get green value
		let b = parseInt(hexColor.substr(5, 2), 16); // Get blue value

		const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

		return luminance > 0.5 ? '#000000' : '#FFFFFF'; // Return black for bright colors, white for dark colors
	}
	function getContrastText(hexColor) {
		// Convert hex to RGB
		let rgbColor = parseInt(hexColor.substring(1), 16); 
		let r = (rgbColor >> 16) & 255;
		let g = (rgbColor >> 8) & 255;
		let b = rgbColor & 255;

		// Calculate contrast
		let contrastR = 255 - r;
		let contrastG = 255 - g;
		let contrastB = 255 - b;

		// Convert RGB to hex
		const contrastColor = ((contrastR << 16) | (contrastG << 8) | contrastB).toString(16);
			
		return "#" + ("000000" + contrastColor).slice(-6);

    
    
    //{ticket?.contact?.displayPic == "ShowPic" && (<Avatar src={ticket?.contact?.profilePicUrl} className={classes.avatar} />)}
    //{ticket?.contact?.displayPic != "ShowPic" && (<Avatar {...stringAvatar(ticket?.contact?.name)} className={classes.avatar2} style={{backgroundColor:stringToColor(ticket?.contact?.name), color:getContrastColor(stringToColor(ticket?.contact?.name))}}/>)}
    

	}



    return (
      <div>
        	<Grid container alignItems="center">
                 <Grid item xs={3} >
                 <Avatar {...stringAvatar(kanbanCard?.username)} style={{ width: "40px", height: "40px", fontWeight:900, backgroundColor:stringToColor(kanbanCard?.username), color:getContrastColor(stringToColor(kanbanCard?.username))}}/>
                  </Grid>
                 <Grid item xs={9}>
                    Resp.: {kanbanCard?.username}<br/>
                    Desde: {cbaTime(kanbanCard?.bookingTzidFrom)}
                 </Grid>
            </Grid>
         
            <Grid container alignItems="center">
                <Grid item xs={4} alignItems="center"  style={{paddingLeft:"10px"}} >
                            <span title={"Info Adicional"}> 
                                <IconButton aria-label="info">
                                    <InfoIcon onClick={() => handleExtraInfo(kanbanCard)} style={{color:blue[500], fontSize: 20}} />
                                </IconButton>
                            </span>
                </Grid>
                <Grid item xs={4} alignItems="center"  style={{paddingLeft:"10px"}}>
                        <span title={"Concluir"}> 
                            <IconButton onClick={() => handleClosingStatus(kanbanCard.bookingId, 0, "Concluído")} >
                                <ThumbUpIcon style={{color:green[500], fontSize: 20}} />
                            </IconButton>
                        </span>
                </Grid>
                <Grid item xs={4} alignItems="center" style={{paddingLeft:"10px"}}>
                        <span title={"Cancelar"}> 
                            <IconButton  onClick={() => handleClosingStatus(kanbanCard.bookingId, 0, "Cancelado", )}>
                                  <ThumbDownIcon style={{color:red[500], fontSize: 20}}/>
                            </IconButton>
                        </span>
                </Grid>
            </Grid>
                          
            
      </div> 
    )

}

const reducer = (state, action) => {
  if (action.type === "LOAD_KANBAN") {
    const kanbanLeafs = action.payload;

    console.log("kanbanLeafs", kanbanLeafs)



    const newKanbanData = [];
    newKanbanData.push({columns: []})
    
    kanbanLeafs.forEach((kanbanLeaf) => {
        const newKanbanCard = [];

        const kanbanCards =  kanbanLeaf.ConfirmedBooking;
       
        kanbanCards.forEach((kanbanCard) => {
          newKanbanCard.push( 
              {
                id: kanbanCard.bookingId,
                title: kanbanCard.bookingName,
                description: formatCard(kanbanCard)   
              }
          )
        })

        if (newKanbanCard[0]){
              newKanbanData[0].columns.push({
                id: kanbanLeaf.id,
                title:  kanbanLeaf.name,
                cards: newKanbanCard
              });

        }else{
              newKanbanData[0].columns.push({
                id: kanbanLeaf.id,
                title:  kanbanLeaf.name,
                cards: []
              });
        } 
    });
    
    
    return newKanbanData[0];
  }

};

const Kanboard = ({nbrColumns}) => {

  const classes = useStyles();

  const [kanbanData, dispatch] = useReducer(reducer, {columns: []});
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");

  console.log("kanban nbrColumns 1a", nbrColumns)
   let kanbanNbrColumns = (nbrColumns==12?"large":"short")
  console.log("kanbanNbrColumns 2", kanbanNbrColumns)



  useEffect(() => {
    // This code will be executed every time nbrColumns changes
        console.log(`nbrColumns changed to ${nbrColumns}`);
        const fetchKanban = async () => {
        try {
          //const { data } = await api.get("/agenda/kanboard", {params: { searchParam, pageNumber, kanbanNbrColumns}});
          let values = {size: (nbrColumns==12?"large":"short")};
          const { data } = await api.post("/agenda/kanboard", values );
          console.log("Kanboard 1",data)
          dispatch({ type: "LOAD_KANBAN", payload: data});
        
        } catch (err) {
        console.log(err);
        }
      }
      fetchKanban();
  }, [nbrColumns]);

  
  useEffect(() => {

    if(!kanbanData.columns[0]?.title){
        const delayDebounceFn = setTimeout(() => {
          const fetchKanban = async () => {
            try {
              //const { data } = await api.get("/agenda/kanboard", {params: { searchParam, pageNumber, kanbanNbrColumns}});
              let values = {size: (nbrColumns==12?"large":"short")};
              const { data } = await api.post("/agenda/kanboard", values );
              console.log("Kanboard 1",data)
              dispatch({ type: "LOAD_KANBAN", payload: data});
            
            } catch (err) {
            console.log(err);
            }
          };
          fetchKanban();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }
  }, [nbrColumns]);


useEffect(() => {
    const intervalId = setInterval(() => {
        if(!kanbanData.columns[0]?.title) {           
            const fetchKanban = async () => {
                try {
                  /*
                    const { data } = await api.get("/agenda/kanboard", {params: { searchParam, pageNumber, kanbanNbrColumns }});
                    */
                    let values = {size: (nbrColumns==12?"large":"short")};
                    const { data } = await api.post("/agenda/kanboard", values );
                   
                    console.log("Kanboard 2",data)
                    dispatch({ type: "LOAD_KANBAN", payload: data});
                } catch (err) {
                    console.log(err);
                }
            };

            fetchKanban();
        }
    }, 10000);  // Adjust this value for the interval in milliseconds

    return () => clearInterval(intervalId);  // Make sure to clear the interval, on completion
}, [nbrColumns]);






  const handleUpdateCard = async (id, from, to) =>{

    const kbData = {
      id,
      from,
      to
    }

    console.log("currBooking 1", kbData)
    try {
      const { data } = await api.post(`/agenda/kanboard/${id}`,kbData);
      console.log("currBooking 2", data)


    
    } catch (err) {
    console.log(err);
    }
  }

  function ControlledBoard() {
  
      const [controlledBoard, setBoard] = useState(kanbanData);
  
      function handleCardMove(_card, source, destination) {
        const updatedBoard = moveCard(controlledBoard, source, destination);

        console.log("CardMove",_card.id, source.fromColumnId, destination.toColumnId)
        handleUpdateCard(_card.id, source.fromColumnId, destination.toColumnId)

        setBoard(updatedBoard);
    }

  
    return (
      <Board onCardDragEnd={handleCardMove} disableColumnDrag>
        {controlledBoard}
      </Board>
    );
  }

  function UncontrolledBoard() {
    return (
      <Board
        allowRemoveLane
        allowRenameColumn
        allowRemoveCard
        onLaneRemove={console.log}
        onCardRemove={console.log}
        onLaneRename={console.log}
        initialBoard={board}
        allowAddCard={{ on: "top" }}
        onNewCardConfirm={(draftCard) => ({
          id: new Date().getTime(),
          ...draftCard
        })}
        onCardNew={console.log}
      />
    );
  }


  return (

            <div style={{height:"100vh"}}>
                <ControlledBoard />
            </div>

  );

};

const cbaTime = date => new Date(date).toLocaleString('pt-BR', { timeZone: 'America/Cuiaba' });

export default Kanboard;
