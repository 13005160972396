import React from 'react';

import {
    makeStyles,
    MenuItem
} from '@material-ui/core';

import { i18n } from "../../translate/i18n";
import StatusTooltip from '../StatusTooltip';
import ChannelTooltip from "../ChannelTooltip"

const useStyles = makeStyles(theme => ({
  connectInfoItem: {
	  justifyContent: "space-between",
	  width: "100%",
  },
  connectionInfo: {
	  alignItems: "center",
	  display: "flex",
	  float: "right",
	  marginTop: "-5px",
	  marginBottom: "-4px",
  },
  connectionInfoSpan: {
	  opacity: "70%",
  }
}))

const QueueItems = queues => {
    const classes = useStyles();
    
    return (
        queues.map((queue) => (
            <MenuItem key={queue.id} value={queue.id} className={classes.connectInfoItem}>
                {queue.name}
            </MenuItem>
        ))
    );
};

export default QueueItems;