import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
	BarChart,
	CartesianGrid,
	Bar,
	XAxis,
	YAxis,
	Label,
	ResponsiveContainer,
} from "recharts";
import { startOfHour, parseISO, format } from "date-fns";
import api from "../../services/api";

import { i18n } from "../../translate/i18n";

import Title from "./Title";
import useTickets from "../../hooks/useTickets";

const Chart = () => {
	const theme = useTheme();

	const currDate = useRef(new Date().toISOString());

	const [tickets, setTickets] = useState([])
	const [timestamp, setTimestamp] = useState(Date.now())

	const initValues = [
		{ time: "00:00", amount: 0 },
		{ time: "01:00", amount: 0 },
		{ time: "02:00", amount: 0 },
		{ time: "03:00", amount: 0 },
		{ time: "04:00", amount: 0 },
		{ time: "05:00", amount: 0 },
		{ time: "06:00", amount: 0 },
        { time: "07:00", amount: 0 },
		{ time: "08:00", amount: 0 },
		{ time: "09:00", amount: 0 },
		{ time: "10:00", amount: 0 },
		{ time: "11:00", amount: 0 },
		{ time: "12:00", amount: 0 },
		{ time: "13:00", amount: 0 },
		{ time: "14:00", amount: 0 },
		{ time: "15:00", amount: 0 },
		{ time: "16:00", amount: 0 },
		{ time: "17:00", amount: 0 },
		{ time: "18:00", amount: 0 },
		{ time: "19:00", amount: 0 },
		{ time: "21:00", amount: 0 },
		{ time: "22:00", amount: 0 },
		{ time: "23:00", amount: 0 },
	]




	const [chartData, setChartData] = useState(initValues);

	
	useEffect(() => {
		setChartData(prevState => {
			let aux = [...prevState];
			if(tickets != []){
				aux.forEach(a => {
					tickets.forEach(ticket => {
						
						format(startOfHour(parseISO(ticket.createdAt)), "HH:mm") === a.time &&
							a.amount++;
					});
				});
	
				return aux;
			}

		});
	}, [tickets]);


    useEffect(() => {
       ''
            const fetchTickets = async() => {

                try {

					const currTimestamp = new Date(timestamp).toISOString();
                    const { data } = await api.get("/ticketstats", {params: {date: currTimestamp}})
					console.log("/ticketstats",data)
					
					setChartData(initValues)
					setTickets(data.tickets)

                } catch (err) {
					console.log("data.tickets.err", err)
                }
            }

            fetchTickets()
       
    }, [timestamp])




  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimestamp(Date.now()); // Updates the timestamp state, causing re-render and new data fetch
    }, 5 * 1000); // 5 minutes

    return () => {
      clearInterval(intervalId); // Clears the interval on component unmount
    };
  }, []);







	return (
		<React.Fragment>
			<Title>{`Atendimentos de hoje`}</Title>
			<ResponsiveContainer>
				<BarChart
					data={chartData}
					barSize={40}
					width={730}
					height={250}
					margin={{
						top: 16,
						right: 16,
						bottom: 0,
						left: 24,
					}}
				>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#156085" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#35b3c5" stopOpacity={0.4} />
                        </linearGradient>
                    </defs>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="time" stroke={theme.palette.text.secondary} />
					<YAxis
						type="number"
						allowDecimals={false}
						stroke={theme.palette.text.secondary}
					>
						<Label
							angle={270}
							position="left"
							style={{ textAnchor: "middle", fill: theme.palette.text.primary }}
						>
							Tickets
						</Label>
					</YAxis>
					<Bar dataKey="amount" fill="url(#colorUv)" />
				</BarChart>
			</ResponsiveContainer>
		</React.Fragment>
	);
};

export default Chart;
