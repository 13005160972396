import React, { useState, useEffect, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {
	DialogActions,
	DialogContent,
    Button,
	
  } from '@material-ui/core';

  import api from "../../services/api";
  import toastError from "../../errors/toastError";
  import CircularProgress from '@material-ui/core/CircularProgress';
  import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0.5),
  },
  paper: {
    padding: theme.spacing(0.1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    elevation: 0,
    margin: 2,
    padding: 3,
    border: "1px solid #ffffff",
  },
  chip: {
    margin: theme.spacing(0.1),
  },
  scrollContainer: {
    maxHeight: '400px', // Adjust this value as needed
    maxWidth:'600px',
    minWidth:'600px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },


}));



const workingHours = Array.from({ length: 25 }, (_, i) => {
  const hour = Math.floor(7 + i / 2);
  const minutes = i % 2 === 0 ? "00" : "30";
  return `${hour.toString().padStart(2, "0")}:${minutes}`;
});

const ScheduleGrid = ({ open, onClose, userId }) => {

	const initialState = {
		id:0,
		name: "",
		email: "",
		password: "",
		profile: "user"
	};
    
    const initialplace =     [{
        placeId: 0,
        placeName: "No Place",
        placeLat: "",
        placeLng: ""
    }]

    const initialResource = {
        positionId:0, 
        positionName:"", 
        placeId:0, 
        positionColor:"#c2c2c2", 
        userId:0
    }

  const classes = useStyles();

    //const daysOfWeek = ["2024-10-16", "3a", "4a", "5a", "6a", "Sab"];

    const [selectedGrids, setSelectedGrids] = useState([]);
    const [selectedGrids2, setSelectedGrids2] = useState([]);
    const [placeId, setPlaceId] = useState(false);
    const [places, setPlaces] = useState(initialplace);
    const [resource, setResource]=useState(initialResource)
    const [scheduledDates, setScheduledDates] = useState([]);
    const [availableDates, setAvailableDates] = useState([]);
    const [user, setUser] = useState(initialState);
    const [selectedQueueIds, setSelectedQueueIds] = useState([]);
    const [whatsappId, setWhatsappId] = useState(false);
    const [currUser, setCurrUser] = useState(initialState)

    const [weekOffset, setWeekOffset] = useState(0)
    const [daysOfWeek, setDaysOfWeek] = useState([]);

    const [saving, setSaving] = useState(false);

    const fetchUserPositions = async (userId) => {
    if (!userId) return;

    console.log("fetchUserPositions", userId)

    try {
        const { data } = await api.get(`/listpositions/${userId}`);

        if(data.currPositions[0]?.placeId){
            setPlaceId(data.currPositions[0].placeId)
            setResource(data.currPositions[0])
            fetchCapacity(data.currPositions[0].positionId, data.currPositions[0].userId);
            fetchAvailability(data.currPositions[0].positionId,data.currPositions[0].userId)
        }	
    } catch (err) {
        toastError(err);
    }
    };

    const fetchPlaces = async () => {
    try {
        const { data } = await api.get(`/places`);
        console.log("data.currPlaces", data.currPlaces)
        setPlaces(data.currPlaces);
    } catch (err) {
        toastError(err);
    }
    };

    const fetchCapacity = async (positionId, userId) => {
        try {
            const { data } = await api.post(`/futureslots`, {positionId, userId});
            setAvailableDates(data.futureSlots);
            console.log("data.futureSlots", data.futureSlots)
        } catch (err) {
            toastError(err);
        }
    };
    
    const fetchAvailability = async (positionId, userId) => {
      console.log("fetchAvailability", positionId, userId)
    try {
        const { data } = await api.post(`/availableslots`, {positionId, userId});
        console.log("data.availableSlots",data.availableSlots)
        setScheduledDates(data.availableSlots);
        setSelectedGrids2(transformToDateTimeStringArray(data.availableSlots))
    } catch (err) {
        toastError(err);
    }
    };
    
    useEffect(() => {
		const fetchUser = async () => {
			if (!userId) return;
			try {
				const { data } = await api.get(`/users/${userId}`);
				setUser(prevState => {
					return { ...prevState, ...data };
				});
				setCurrUser(prevState => {
					return { ...prevState, ...data };
				});

				const userQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(userQueueIds);
				setWhatsappId(data.whatsappId ? data.whatsappId : '');
			} catch (err) {
				toastError(err);
			}
		};

		fetchUser();
	  }, [userId, open]);

	  useEffect(() => {	
		const delayDebounceFn = setTimeout(() => {
		  fetchPlaces();
		}, 1000);
		return () => clearTimeout(delayDebounceFn);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, []);

    useEffect(() => {	
        const initialWeekOffset = 0; // 0 for current week, 1 for next week, 2 for the following week, etc.
        setDaysOfWeek(getWeekDates(initialWeekOffset));
      }, []);

	  useEffect(() => {	
	const delayDebounceFn = setTimeout(() => {
		
		fetchUserPositions(userId)

	}, 1000);
	return () => clearTimeout(delayDebounceFn);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [userId]);

    const getMondayOfWeek = (weekOffset = 0) => {
      const date = new Date();
      const day = date.getDay();
      const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
      const monday = new Date(date.setDate(diff));
      monday.setDate(monday.getDate() + weekOffset * 7); // adjust for the week offset
      return monday;
    };
    
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-based
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    
    const getWeekDates = (weekOffset = 0) => {
      const monday = getMondayOfWeek(weekOffset);
      const weekDates = [];
    
      for (let i = 0; i < 6; i++) {
        const date = new Date(monday);
        date.setDate(monday.getDate() + i);
        weekDates.push(formatDate(date));
      }
    
      return weekDates;
    };

    const addUniqueGrid = (day, time) => {
      const value = `${day} ${time}`;
      if (!selectedGrids2.includes(value)) {
        setSelectedGrids2((prev) => [...prev, value]);
      } else {
        // If the value already exists, remove it (toggle functionality)
        setSelectedGrids2((prev) => prev.filter((item) => item !== value));
      }
    };

    const handleDateTime = (day, time) => {
      console.log(`Selected: ${day} ${time}`);
      console.log(`SelectedGrids:`, JSON.stringify(selectedGrids2));
      addUniqueGrid(day, time);
    };

    const isSelected = (day, time) => {
      return selectedGrids2.includes(`${day} ${time}`);
    };
    
    const handleClose = () => {
      onClose();
    };

    useEffect(() => {
      setDaysOfWeek(getWeekDates(weekOffset));
    }, [weekOffset]);
  
    const handleNextWeek = () => {
      setWeekOffset(prevOffset => prevOffset + 1);
    };
  
    const handlePreviousWeek = () => {
      setWeekOffset(prevOffset => prevOffset - 1);
    };

    const handleInsertAvailability = async () => {
     
      setSaving(true);

			console.log("availableDates",selectedGrids2)
			console.log("userId", userId)
			console.log("resource",resource.positionId)

			let payload = {	userId:  userId,
							positionId:resource.positionId,
							toInsert:selectedGrids2
			}
			try {
        const { data } = await api.post(`/insertavailabilitybygrid`, [payload]);
        fetchCapacity(resource.positionId, userId);
      } catch (err) {
        toast.error(err)
        //toastError(err);
      } finally {
        setSaving(false);
        toast.success("Grelha de disponibilidade atualizada");
      }
	  };

    const transformToDateTimeStringArray = (slots) => {
      return slots.map(slot => {
          const dateTimeString = `${slot.slotDate} ${slot.slotHour}`;
          return dateTimeString;
      });
  };



  return (
    <div className={classes.root}>

        <DialogContent dividers style={{ overflowX: 'hidden'}}>
        <div className={classes.scrollContainer}>
            <Grid container spacing={1}>
                {daysOfWeek.map((day) => (
                <Grid item xs={2} key={day}>
                    <Paper className={classes.paper}>
                    <Typography style={{ fontSize: "0.9em", color: "#000000" }}>
                        {day}
                    </Typography>
                    </Paper>
                </Grid>
                ))}
                
                    {workingHours.map((time) => (
                    <React.Fragment key={time}>
                        <Grid container spacing={1}>
                        {daysOfWeek.map((day) => (
                            <Grid item xs={2} key={`${day}-${time}:00`}>
                            <Paper className={classes.paper}>
                                <Chip
                                label={time}
                                onClick={() => handleDateTime(day,  `${time}:00`)}
                                className={classes.chip}
                                style={{
                                    fontSize: "0.9em",
                                    color: isSelected(day, time) ? "#ffffff" : "#000000",
                                    backgroundColor: isSelected(day,`${time}:00`)
                                    ? "#0dc681"
                                    : "#ffffff",
                                }}
                                />
                            </Paper>
                            </Grid>
                        ))}
                        </Grid>
                    </React.Fragment>
                    ))}
                
            </Grid>
        </div>
        </DialogContent>
        <DialogActions >	            
            <Button
										onClick={handlePreviousWeek}
										color="primary"
										variant="outlined"
									>
										&lt;&lt; Semana Anterior
            </Button>				
            <Button
										onClick={handleNextWeek}
										color="primary"
										variant="outlined"
									>
										Semana Seguinte &gt;&gt;
            </Button>
            <Button
										onClick={handleClose}
										color="primary"
										
										variant="outlined"
									>
										Fechar
									</Button>
									<Button
										color="primary"
										onClick={handleInsertAvailability}
										variant="contained"
										className={classes.btnWrapper}
									>
										Salvar
										
									</Button>
								</DialogActions>
    </div>
  );
};

export default ScheduleGrid;
