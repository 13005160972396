import React, { useContext, useEffect, useState, useReducer } from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { socketConnection } from "../services/socket";

import VideoModal from "../components/VideoModal";
import Paper from "@material-ui/core/Paper";
import red from '@material-ui/core/colors/red';


import { CAccordion } from '@coreui/react'
import { CAccordionBody } from '@coreui/react'
import { CAccordionHeader } from '@coreui/react'
import { CAccordionItem } from '@coreui/react'

import "../assets/atendosim.css"
import {
   Tooltip
} from '@material-ui/core';


//import Timeline from 'react-timeline-semantic-ui';
import {Timeline, TimelineEvent, TimelineBlip} from 'react-event-timeline'
import Grid from "@material-ui/core/Grid"

import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CheckIcon from '@material-ui/icons/Check';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import { Badge } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ForumIcon from "@material-ui/icons/Forum";
import GroupIcon from '@material-ui/icons/Group';
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import ControlPointIcon from '@material-ui/icons/ControlPoint';


import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import CloudDoneIcon from '@material-ui/icons/CloudDone';





import NewReleasesIcon from '@material-ui/icons/NewReleases';

import TodayIcon from '@material-ui/icons/Today';
import TimerIcon from '@material-ui/icons/Timer';

import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import MenuBookIcon from '@material-ui/icons/MenuBook';
import CategoryIcon from '@material-ui/icons/Category';

import  SoftPhone  from '../components/SoftPhone'

import ManageBotsOutlinedIcon from "@material-ui/icons/InsertCommentOutlined";
import AccountIcon from "@material-ui/icons/AccountCircle";

import AccountBoxTwoToneIcon from '@material-ui/icons/AccountBoxTwoTone';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import AssignmentIndTwoToneIcon from '@material-ui/icons/AssignmentIndTwoTone';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

import NearMeIcon from '@material-ui/icons/NearMe';
import MapIcon from '@material-ui/icons/Map';

import { purple } from '@material-ui/core/colors';
import { green } from '@material-ui/core/colors';

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";

import { useHistory } from "react-router-dom";

import { Gradient } from 'react-gradient';
import { isArray } from "lodash";
import api from "../services/api";
import toastError from "../errors/toastError";

const useStyles = makeStyles((theme) => ({
  
  none:{},

	accordionButton: {
	  marginLeft: "5px",
    marginBottom: "10px",
	  border: 0,
    borderRadius: 15,
    width: "200px",
    color: "#4a148c"
	},
  subHeader: {
    color: '#ffffff', 
    fontSize: 16, 
    width:200, 
    borderRadius:10,
    textAlign:  "left"
  },
  paperItems:{
      marginLeft: "10px",
      marginTop: "5px",
      marginBottom: "10px",
      width:215, 
      borderRadius:10
  },
  label1:{
    paddingLeft:"20px",
  },
  label2:{
    display: "none",
  },
  rotateIcon: {
    animation: "$spin 2s linear infinite"
  },

  shakeIcon: {
    animation: "$shake .5s infinite"
  },



  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)"
    },
    "100%": {
      transform: "rotate(360deg)"
    }
  },


 "@keyframes shake": {
    "0%": { transform: "translate(3px, 0px) rotate(0deg)"},
    "10%": { transform: "translate(-3px, -0px) rotate(0deg)"},
    "20%": { transform: "translate(3px, 0px) rotate(0deg)"},
    "30%": { transform: "translate(-3px, 0px) rotate(0deg)"},
    "40%": { transform: "translate(0px, 0px) rotate(0deg)"},
    "50%": { transform: "translate(0px, 0px) rotate(-1deg)" },
    "60%": { transform: "translate(-3px, 0px) rotate(0deg)"},
    "70%": { transform: "translate(3px, 0px) rotate(0deg)"},
    "80%": { transform: "translate(-3px, 0px) rotate(0deg)"},
    "90%": { transform: "translate(0px, 0px) rotate(0deg)"},
    "100%": { transform: "translate(0px, 0px) rotate(0deg)"}
  }






  }));

  function ForwardLink(props) {
    const { to } = props;
    const renderLink = React.useMemo(
      () =>
        React.forwardRef(() => (
          <RouterLink to={to} />
        )),
      [to]
    );

    return (<></>);

}

  function ListItemLink(props) {
        const { icon, primary, to, className } = props;
        const renderLink = React.useMemo(
          () =>
            React.forwardRef((itemProps, ref) => (
              <RouterLink to={to} ref={ref} {...itemProps} />
            )),
          [to]
        );

        return (
          <li>
            <ListItem button component={renderLink} className={className}>
              {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
              <ListItemText primary={primary} />
            </ListItem>
          </li>
        );

  }

  const reducer = (state, action) => {
    if (action.type === "LOAD_CHATS") {
      const chats = action.payload;
      const newChats = [];
  
      if (isArray(chats)) {
        chats.forEach((chat) => {
          const chatIndex = state.findIndex((u) => u.id === chat.id);
          if (chatIndex !== -1) {
            state[chatIndex] = chat;
          } else {
            newChats.push(chat);
          }
        });
      }
  
      return [...state, ...newChats];
    }
  
    if (action.type === "UPDATE_CHATS") {
      const chat = action.payload;
      const chatIndex = state.findIndex((u) => u.id === chat.id);
  
      if (chatIndex !== -1) {
        state[chatIndex] = chat;
        return [...state];
      } else {
        return [chat, ...state];
      }
    }
  
    if (action.type === "DELETE_CHAT") {
      const chatId = action.payload;
  
      const chatIndex = state.findIndex((u) => u.id === chatId);
      if (chatIndex !== -1) {
        state.splice(chatIndex, 1);
      }
      return [...state];
    }
  
    if (action.type === "RESET") {
      return [];
    }
  
    if (action.type === "CHANGE_CHAT") {
      const changedChats = state.map((chat) => {
        if (chat.id === action.payload.chat.id) {
          return action.payload.chat;
        }
        return chat;
      });
      return changedChats;
    }
  };
  

  const MainListItems = (props) => {


    const gradients = [
      ['#a168e7', '#a10882']
  ];

  const classes = useStyles();

  const { drawerClose, proMenu } = props;

  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [videoModalOpen,  setVideoModalOpen] = useState(false);
  const [videoUrl,  setVideoUrl] = useState("");
  const [chats, dispatch] = useReducer(reducer, []);
  const [searchParam] = useState("");
  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = 0;
    const socket = socketConnection({ companyId });

    socket.on(`company-0-chat`, (data) => {
      if (data.action === "new-message") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
      if (data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    if (unreadsCount > 0) {
      setInvisible(false);
    } else {
      setInvisible(true);
    }
  }, [chats, user.id]);


  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };

 const  openModal = () => {
    setVideoModalOpen(true)
  };
  const history = useHistory();

  const routeChange = (path) =>{ 
    history.push(path);
  }


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING" ||
            whats.status === "STALLED"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const CustomToolTip = ({ title, content, children }) => {
    const classes = useStyles();
  
    return (
      <Tooltip
        arrow
        classes={{
          tooltip: classes.tooltip,
          popper: classes.tooltipPopper,
        }}
        title={
          <React.Fragment>
            <Typography gutterBottom color="inherit">
              {title}
            </Typography>
            {content && <Typography>{content}</Typography>}
          </React.Fragment>
        }
      >
        {children}
      </Tooltip>
    );
  };

  const handleCloseVideoModal = () => {
    setVideoModalOpen(false)
  };

  const vars = { 
    '--cui-accordion-bg': '#ffffff',
    '--cui-accordion-border-color': '#beaed2',
    '--cui-accordion-border-width': "1",
    '--cui-accordion-border-radius': "5",
    "--cui-accordion-body-padding-x": "0!important",
    "--cui-accordion-body-padding-y": "10px"
}

const handleVideoModalOpen = (videoUrl)=>{
  setVideoUrl(videoUrl);
  setVideoModalOpen(true)

}



return (
  <div onClick={drawerClose}>
            <div >
                <CAccordion style={vars} flush activeItemKey={1}>
                  <CAccordionItem itemKey={1} style={{border:0}}>
                    <CAccordionHeader className={classes.accordionButton}>
                    <ListSubheader className={classes.subHeader} >
                            {<AccountCircleTwoToneIcon style={{ color: '#ffffff', fontSize: 33 }} />}
                            <span style={{paddingLeft:20}}>{i18n.t("mainDrawer.listItems.operation")}</span>
                    </ListSubheader>
                    </CAccordionHeader>

                    <CAccordionBody>
                        <Paper elevation={3} className={classes.paperItems}>

                                        {!connectionWarning  && ((<>
                                            <ListItemLink
                                                to="/tickets"
                                                primary={i18n.t("mainDrawer.listItems.tickets")}
                                                icon={
                                                  <>
                                                    <CustomToolTip
                                                            title={i18n.t("mainDrawer.listItems.tickets")}
                                                            content={i18n.t("mainDrawer.listItems.ticketsdesc")}
                                                            className={classes.none}
                                                    >
                                                          <><WhatsAppIcon style={{ color: '#2E7D32', fontSize: 33 }} className={classes.rotateIcon} /></>
                                                            
                                                    </CustomToolTip>
                                                  </>
                                                  
                                                
                                                }
                                                />

                                        </>))}

                                        {connectionWarning  && ((<>
                                            <ListItemLink
                                                to="/tickets"
                                                primary={i18n.t("mainDrawer.listItems.tickets")}
                                                icon={
                                                  <>
                                                    <CustomToolTip
                                                            title={i18n.t("mainDrawer.listItems.tickets")}
                                                            content={i18n.t("mainDrawer.listItems.ticketsdesc")}
                                                            className={classes.none}
                                                    >
                                                            <><WhatsAppIcon style={{ color: red[800], fontSize: 33 }} className={classes.shakeIcon} /></>
                                                    </CustomToolTip>
                                                  </>
                                                  
                                        
                                                }
                                                />

                                        </>))}


                                        <ForwardLink
                                            to="/newtickets"
                                        />   

                                        <ListItemLink
                                            to="/chats"
                                            primary={i18n.t("mainDrawer.listItems.internalChat")}
                                            icon={
                                              <>
                                                {invisible && (<><ForumIcon  style={{ color: '#152d70', fontSize: 33}}  /></>)}
                                                {!invisible && (<><ForumIcon  className={classes.shakeIcon} style={{color:red[600], fontSize: 33}}  /></>)}
                                              </>  
                                              }
                                        />   

                                        <ListItemLink
                                          to="/scheduledMessages"
                                          primary={i18n.t("mainDrawer.listItems.scheduledMessages")}
                                        
                                          icon={<CustomToolTip
                                            title={i18n.t("mainDrawer.listItems.scheduledMessages")}
                                            content={i18n.t("mainDrawer.listItems.scheduledMessagesdesc")}
                                          >
                                            <TimerIcon style={{ color: '#152d70', fontSize: 33 }} />
                                          </CustomToolTip>}
                                        />
                                            
                                        <ListItemLink
                                          to="/contacts"
                                          primary={i18n.t("mainDrawer.listItems.contacts")}
                                          icon={<CustomToolTip
                                            title={i18n.t("mainDrawer.listItems.contacts")}
                                            content={i18n.t("mainDrawer.listItems.contactsdesc")}
                                          >
                                            <ContactPhoneOutlinedIcon style={{ color: '#152d70', fontSize: 33 }} />
                                          </CustomToolTip>}
                                        />

                                        <ListItemLink
                                          to="/quickAnswers"
                                          primary={i18n.t("mainDrawer.listItems.quickAnswers")}
                                          icon={<CustomToolTip
                                            title={i18n.t("mainDrawer.listItems.quickAnswers")}
                                            content={i18n.t("mainDrawer.listItems.quickAnswersdesc")}
                                          >
                                            <QuestionAnswerOutlinedIcon style={{ color: '#152d70', fontSize: 33 }} />
                                          </CustomToolTip>}
                                        />
                                        <ListItemLink
                                              to="/advscheduler"
                                              primary={i18n.t("mainDrawer.listItems.agenda")}
                                              icon={<CustomToolTip
                                                title={i18n.t("mainDrawer.listItems.agenda")}
                                                content={i18n.t("mainDrawer.listItems.agendadesc")}
                                              >
                                                <TodayIcon style={{ color: '#152d70', fontSize: 33 }} />
                                              </CustomToolTip>}

                                            />
                        </Paper>
                    </CAccordionBody>
                  </CAccordionItem>
                
    
              
                  <CAccordionItem itemKey={2} style={{border:0}}>
                    <CAccordionHeader className={classes.accordionButton}>
                          <ListSubheader className={classes.subHeader}>
                                {<AccountBoxTwoToneIcon style={{ color: '#ffffff', fontSize: 33 }} />}
                                <span style={{paddingLeft:20}}>{i18n.t("mainDrawer.listItems.supervision")}</span>
                                
                          </ListSubheader>
                    </CAccordionHeader>
                    <CAccordionBody>
                          <Can
                            role={user.profile}
                            perform="drawer-superv-items:view"
                            yes={() => (
                              <>
                              <Paper elevation={3} className={classes.paperItems}>
                                  <ListItemLink
                                      to="/Tableaux"
                                      primary={i18n.t("mainDrawer.listItems.dashboard")}
                                      icon={<CustomToolTip
                                        title={i18n.t("mainDrawer.listItems.dashboard")}
                                        content={i18n.t("mainDrawer.listItems.dashboarddesc")}
                                      >
                                        <DashboardOutlinedIcon style={{ color: '#152d70', fontSize: 33 }} />
                                      </CustomToolTip>}
                                    />

                                  <ListItemLink inset
                                    style={{ color: '#152d70' }}
                                    to="/connections"
                                    primary={i18n.t("mainDrawer.listItems.connections")}
                                    icon={<CustomToolTip
                                      title={i18n.t("mainDrawer.listItems.connections")}
                                      content={i18n.t("mainDrawer.listItems.connectionsdesc")}
                                    >
                                      <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                                      <SyncAltIcon style={{ color: '#152d70', fontSize: 33 }} />
                                    </Badge>
                                    </CustomToolTip>}
                                  />
                        
                                  <ListItemLink
                                    to="/queues"
                                    primary={i18n.t("mainDrawer.listItems.queues")}
                                    icon={<CustomToolTip
                                      title={i18n.t("mainDrawer.listItems.queues")}
                                      content={i18n.t("mainDrawer.listItems.queuesdesc")}
                                    >
                                      <ControlCameraIcon style={{ color: '#152d70', fontSize: 33 }} />
                                    </CustomToolTip>} 
                                  />

                                  <ListItemLink
                                    to="/users"
                                    primary={i18n.t("mainDrawer.listItems.users")}
                                    icon={<CustomToolTip
                                      title={i18n.t("mainDrawer.listItems.users")}
                                      content={i18n.t("mainDrawer.listItems.usersdesc")}
                                    >
                                      <PeopleAltOutlinedIcon style={{ color: '#152d70', fontSize: 33 }} />
                                    </CustomToolTip>}

                                  />

                                  <ListItemLink
                                      to="/upgradeinfo"
                                      primary={i18n.t("mainDrawer.listItems.upgrades")}
                                      icon={<CustomToolTip
                                        title={i18n.t("mainDrawer.listItems.upgrades")}
                                        content={i18n.t("mainDrawer.listItems.upgradesdesc")}
                                      >
                                          <ControlPointIcon style={{ color: '#152d70', fontSize: 33 }}/>
                                      </CustomToolTip>}
                                  />

                                  <ListItemLink
                                      to="/announcements"
                                      primary={i18n.t("mainDrawer.listItems.announcements")}
                                      icon={<CustomToolTip
                                        title={i18n.t("mainDrawer.listItems.announcements")}
                                        content={i18n.t("mainDrawer.listItems.announcementdesc")}
                                      >
                                          <NewReleasesIcon  style={{ color: '#152d70', fontSize: 33 }}/>
                                      </CustomToolTip>}
                                    />

                              </Paper>
                                
                              </>
                              
                            )}
                          />
                    </CAccordionBody>
                  </CAccordionItem>

                  <CAccordionItem itemKey={3}>
                    <CAccordionHeader className={classes.accordionButton}>
                          <ListSubheader className={classes.subHeader}>
                              {<OpenInNewIcon style={{ color: '#ffffff', fontSize: 33 }} />}
                              <span style={{paddingLeft:20}}>{i18n.t("mainDrawer.listItems.business")}</span>
                              
                          </ListSubheader>
                    </CAccordionHeader>
                    <CAccordionBody>
                    <Can
                      role={user.profile}
                      perform="drawer-admin-items:view"
                      yes={() => (
                        <>
                            <Paper elevation={3} className={classes.paperItems}>
                            <ListItemLink
                                    to="/broadcasts"
                                    primary={i18n.t("mainDrawer.listItems.broadcasts")}
                                    icon={<CustomToolTip
                                      title={i18n.t("mainDrawer.listItems.broadcasts")}
                                      content={i18n.t("mainDrawer.listItems.broadcastsdesc")}
                                    >
                                      <AnnouncementIcon style={{ color: '#152d70', fontSize: 33 }} />
                                    </CustomToolTip>}
                                  />
                                  <ListItemLink
                                    to="/campaign"
                                    primary={i18n.t("mainDrawer.listItems.campaign")}
                                    icon={<CustomToolTip
                                      title={i18n.t("mainDrawer.listItems.campaign")}
                                      content={i18n.t("mainDrawer.listItems.campaigndesc")}
                                    >
                                      <NearMeIcon style={{ color: '#152d70', fontSize: 33 }} />
                                    </CustomToolTip>}
                                  />
                                  <ListItemLink
                                    to="/categories"
                                    primary={i18n.t("mainDrawer.listItems.categories")}
                                    icon={<CustomToolTip
                                      title={i18n.t("mainDrawer.listItems.categories")}
                                      content={i18n.t("mainDrawer.listItems.categoriesdesc")}
                                    >
                                      <CategoryIcon style={{ color: '#152d70', fontSize: 33 }} />
                                    </CustomToolTip>}
                                  />
                                  <ListItemLink
                                    to="/catalog"
                                    primary={i18n.t("mainDrawer.listItems.catalog")}
                                    icon={<CustomToolTip
                                      title={i18n.t("mainDrawer.listItems.catalog")}
                                      content={i18n.t("mainDrawer.listItems.catalogdesc")}
                                    >
                                      <MenuBookIcon style={{ color: '#152d70', fontSize: 33 }} />
                                    </CustomToolTip>}
                                  />
                                  
                            </Paper>
                          </>
                              
                        )}
                      />

                    </CAccordionBody>
                  </CAccordionItem>


                  </CAccordion>


          </div>
            

        
  </div>
);



};

export default MainListItems;

