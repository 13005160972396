import React, { useState, useEffect, useContext, useReducer } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import ColorPicker from "../ColorPicker";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { purple } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

import ListItemText from '@material-ui/core/ListItemText';
import { DateTime } from "luxon";

import { FixedSizeList } from 'react-window';
import Grid from '@material-ui/core/Grid';
import SchedulerGrid from "../SchedulerGrid"


import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Select,
	InputLabel,
	MenuItem,
	FormControl,
	TextField,
	InputAdornment,
	IconButton,
	Tab
  } from '@material-ui/core';
  import { Colorize } from "@material-ui/icons";

import TabPanel from "../TabPanel";
import Tabs from "@material-ui/core/Tabs";

import { Visibility, VisibilityOff } from '@material-ui/icons';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useWhatsApps from "../../hooks/useWhatsApps";



const useStyles = makeStyles(theme => ({
	ticketsWrapper: {
		position: "relative",
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflow: "hidden",
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	  },
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth:300,
	},
	list: {
		maxHeight: '400px',
		overflowY: 'auto'
	  },
	  purple1: {
		color: purple[500]
	},
}));

const PurpleSwitch = withStyles({
	switchBase: {
	  color: purple[300],
	  '&$checked': {
		color: purple[500],
	  },
	  '&$checked + $track': {
		backgroundColor: purple[500],
	  },
	},
	checked: {},
	track: {},
  })(Switch);

const reducer = (state, action) => {
  
	if (action.type === "LOAD_CAMPAIGN") {
			  const campaigns = action.payload;
			  const newcampaigns = [];
			  
			  campaigns.forEach((campaign) => {
					  const campaignIndex = state.findIndex((c) => c.id === campaign.id);
					  if (campaignIndex !== -1) {
						state[campaignIndex] = campaign;
					  } else {
						newcampaigns.push(campaign);
					  }
			  });
  
			 
			  
			  return [...state, ...newcampaigns];
	}
  
	if (action.type === "LOAD_PLANS") {
	  const plans = action.payload;
	  const newplans = [];
	  
	  plans.forEach((plan) => {
		const planIndex = state.findIndex((p) => p.id === plan.id);
		if (planIndex !== -1) {
		  state[planIndex] = plan;
		} else {
		  newplans.push(plan);
		}
		
	  });
	 
	  return [...state, ...newplans];
	}
  
	if (action.type === "LOAD_MSGS") {
	  const texts = action.payload;
	  const newtexts = [];
	  
	  texts.forEach((text) => {
		const textIndex = state.findIndex((m) => m.id === text.id);
		if (textIndex !== -1) {
		  state[textIndex] = text;
		} else {
		  newtexts.push(text);
		}
		
	  });
	 
	  return [...state, ...newtexts];
	}
  
  
	
  }
  

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email").required("Required"),
});

const UserModal = ({ open, onClose, userId }) => {
	const classes = useStyles();

	const initialState = {
		id:0,
		name: "",
		email: "",
		password: "",
		profile: "user"
	};

	const initialplace =     [{
        placeId: 0,
        placeName: "No Place",
        placeLat: "",
        placeLng: ""
    }]

	const initialResource = {
		positionId:0, 
		positionName:"", 
		placeId:0, 
		positionColor:"#c2c2c2", 
		userId:0
	}

	const { user: loggedInUser } = useContext(AuthContext);

	const [user, setUser] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [showPassword, setShowPassword] = useState(false);
	const [whatsappId, setWhatsappId] = useState(false);
	const [placeId, setPlaceId] = useState(false);
	const [places, setPlaces] = useState(initialplace);
	const {loading, whatsApps} = useWhatsApps();
	const [saving, setSaving] = useState(false);
	const [tab, setTab] = useState("user");
	const [resourceData, setResourceData] = useReducer(reducer, []);
	const [resourceSchedules, setResourceSchedules] = useReducer(reducer, []);
	const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false)
	const [color, setColor] = useState("")
	const [bgColor, setBgColor]= useState("#c2c2c2")
	const [resource, setResource]=useState(initialResource)
	const [currUser, setCurrUser] = useState(initialState)
	const [currPositions, setCurrPositions] = useState([])


	const [scheduledDates, setScheduledDates] = useState([]);
	const [availableDates, setAvailableDates] = useState([]);

	const [checked_a, setChecked_a] = useState(false);
	const [checked_b, setChecked_b] = useState(false);
	const [checked_c, setChecked_c] = useState(false);
	const [checked_d, setChecked_d] = useState(false);
  
	const [state, setState] = useState({
		checkedA: false,
		checkedB: false,
		checkedC: false,
		checkedD: false,
	});

	useEffect(() => {
		const fetchUser = async () => {
			if (!userId) return;
			try {
				const { data } = await api.get(`/users/${userId}`);
				setUser(prevState => {
					return { ...prevState, ...data };
				});
				setCurrUser(prevState => {
					return { ...prevState, ...data };
				});

				const userQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(userQueueIds);
				setWhatsappId(data.whatsappId ? data.whatsappId : '');
			} catch (err) {
				toastError(err);
			}
		};

		fetchUser();
	}, [userId, open]);

	useEffect(() => {	
		const delayDebounceFn = setTimeout(() => {
		  fetchPlaces();
		}, 1000);
		return () => clearTimeout(delayDebounceFn);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, []);

	useEffect(() => {	
	const delayDebounceFn = setTimeout(() => {
		
		fetchUserPositions(userId)

	}, 1000);
	return () => clearTimeout(delayDebounceFn);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId]);

	const fetchUserPositions = async (userId) => {
		if (!userId) return;

		console.log("fetchUserPositions", userId)

		try {
			const { data } = await api.get(`/listpositions/${userId}`);

			if(data.currPositions[0]?.placeId){
				setPlaceId(data.currPositions[0].placeId)
				setResource(data.currPositions[0])
				fetchCapacity(data.currPositions[0].positionId, data.currPositions[0].userId);
				fetchAvailability(data.currPositions[0].positionId,data.currPositions[0].userId)
			}	
		} catch (err) {
			toastError(err);
		}
	};

	const handleReset = async () => {
		if (!userId) return;

		console.log("Reset Positions", userId)

		setPlaceId(0)
		setResource([])
		setAvailableDates([]);
		setScheduledDates([])
		await  fetchUserPositions(userId)
	
	}

	const fetchPlaces = async () => {
		try {
			const { data } = await api.get(`/places`);
			console.log("data.currPlaces", data.currPlaces)
			setPlaces(data.currPlaces);
		} catch (err) {
			toastError(err);
		}
	};

	const fetchCapacity = async (positionId, userId) => {
	try {
		const { data } = await api.post(`/futureslots`, {positionId, userId});
		setAvailableDates(data.futureSlots);
	} catch (err) {
		toastError(err);
	}
	};

	const fetchAvailability = async (positionId, userId) => {
	try {
		const { data } = await api.post(`/availableslots`, {positionId, userId});
		console.log("data.availableSlots",data.availableSlots)
		setScheduledDates(data.availableSlots);
	} catch (err) {
		toastError(err);
	}
	};

	const handleColorChange = (color) =>{
		console.log("handleColorChange", color)
		setBgColor(color)
		setColor(color)
	}

	const handleClose = () => {
		onClose();
		setUser(initialState);
		setScheduledDates([]);
		setAvailableDates([]);
	};

	const handleSaveUser = async values => {
		const userData = { ...values, whatsappId, queueIds: selectedQueueIds };
		try {
			if (userId) {
				await api.put(`/users/${userId}`, userData);
			} else {
				await api.post("/users", userData);
			}
			toast.success(i18n.t("userModal.success"));
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	const handleSaveResource = async (values) =>  {

		let saveData=values;
		saveData.userId=user.id
		console.log("saveData", saveData)

		
		try {
			
				let data = await api.post(`/ws/capacityposition`, saveData);
				setResource(data)
				toast.success(i18n.t("userModal.success"));
		} catch (err) {
			toastError(err);
		}
		//handleClose();
	};

	const handleTabChange = (e, newValue) => {
		console.log("handleTabChange", newValue)
		setTab(newValue);
	};

	const handleInsertAvailability = async () => {
		
			console.log("availableDates",scheduledDates)
			console.log("userId", userId)
			console.log("resource",resource.positionId)

			let payload = {	userId:  userId,
							positionId:resource.positionId,
							toInsert:scheduledDates,
							toRemove:availableDates
			}
			const { data } = await api.post(`/insertavailability`, [payload] );
			
			fetchCapacity(resource.positionId, userId);
	};

	function getNextSevenDates() {
		const dates = [];
		for (var i = 1; i <= 100; i++) {
		dates.push(DateTime.now().plus({days:i}).toISODate());
		}
		return dates;
	}

	const moveToAvailableDates = (slotId) => {

		
	// Find the slot by given slotId from scheduledDates
	const slotToMove = scheduledDates.find(slot => slot.slotId === slotId);

	// If found, proceed with operation
	if (slotToMove) {
		// Add the slot to availableDates
		setAvailableDates(prevAvailableDates => [...prevAvailableDates, slotToMove]);

		// Remove the slot from scheduledDates
		setScheduledDates(prevScheduledDates => prevScheduledDates.filter(slot => slot.slotId !== slotId));
	} else {
		console.error(`No slot found with id ${slotId}`);
	}
	};

	//calendario
	function handleAvailableDateClick(slotId) {
		const slotToMove = availableDates.find(slot => slot.slotId === slotId);
		console.log("availableDates",slotToMove )

		if(state.checkedA===true){
			console.log("move all records")
			setScheduledDates(prevScheduledDates => [...prevScheduledDates, ...availableDates].sort((a, b) => a.slotId - b.slotId));
			setAvailableDates([]);
		}else

		if(state.checkedB===true){
			console.log("move Today records")
			setScheduledDates(prevScheduledDates => [...prevScheduledDates, ...availableDates.filter(slot => slot.slotDate === slotToMove.slotDate)].sort((a, b) => a.slotId - b.slotId));
			setAvailableDates(availableDates.filter(slot => slot.slotDate !== slotToMove.slotDate));
		}else
		
		if(state.checkedC===true){
			console.log("move Morning records")
			setScheduledDates(prevScheduledDates => [...prevScheduledDates, ...availableDates.filter(slot => slot.slotDate === slotToMove.slotDate && slot.slotHour < '12:30:00')].sort((a, b) => a.slotId - b.slotId));
			setAvailableDates(availableDates.filter(slot => slot.slotDate !== slotToMove.slotDate || (slot.slotDate === slotToMove.slotDate && slot.slotHour >= '12:30:00')));
		}else
		
		if(state.checkedD===true){
			console.log("move Afternoon records")
			setScheduledDates(prevScheduledDates =>  [...prevScheduledDates, ...availableDates.filter(slot => slot.slotDate === slotToMove.slotDate && slot.slotHour >= '12:30:00')].sort((a, b) => a.slotId - b.slotId));
			setAvailableDates(availableDates.filter(slot => slot.slotDate !== slotToMove.slotDate || (slot.slotDate === slotToMove.slotDate && slot.slotHour < '12:30:00')));
		}else

		if (slotToMove) {
			setScheduledDates(prevScheduledDates => [...prevScheduledDates, slotToMove].sort((a, b) => a.slotId - b.slotId));
			setAvailableDates(prevAvailabledDates => prevAvailabledDates.filter(slot => slot.slotId !== slotId));
		} 
	}
	//grade
	function handleScheduledDateClick(slotId) {
		const slotToMove = scheduledDates.find(slot => slot.slotId === slotId);
		console.log("scheduledDates",slotToMove )
		

		if(state.checkedA===true){
			console.log("move all records")
			setAvailableDates(prevScheduledDates => [...prevScheduledDates, ...scheduledDates].sort((a, b) => a.slotId - b.slotId));
			setScheduledDates([]);
		}else

		if(state.checkedB===true){
			console.log("move Today records")
			setAvailableDates(prevAvailableDates => [...prevAvailableDates, ...scheduledDates.filter(slot => slot.slotDate === slotToMove.slotDate)].sort((a, b) => a.slotId - b.slotId));
			setScheduledDates(scheduledDates.filter(slot => slot.slotDate !== slotToMove.slotDate));
		}else
		
		if(state.checkedC===true){
			console.log("move Morning records")
		
			setAvailableDates(prevAvailableDates => [...prevAvailableDates, ...scheduledDates.filter(slot => slot.slotDate === slotToMove.slotDate && slot.slotHour < '12:30:00')].sort((a, b) => a.slotId - b.slotId));
			setScheduledDates(scheduledDates.filter(slot => slot.slotDate !== slotToMove.slotDate || (slot.slotDate === slotToMove.slotDate && slot.slotHour >= '12:30:00')));
		}else
		
		if(state.checkedD===true){
			console.log("move Afternoon records")
			setAvailableDates(prevAvailableDates => [...prevAvailableDates, ...scheduledDates.filter(slot => slot.slotDate === slotToMove.slotDate && slot.slotHour >= '12:30:00')].sort((a, b) => a.slotId - b.slotId));
			setScheduledDates(scheduledDates.filter(slot => slot.slotDate !== slotToMove.slotDate || (slot.slotDate === slotToMove.slotDate && slot.slotHour < '12:30:00')));
		}else

		if (slotToMove) {
			setAvailableDates(prevScheduledDates => [...prevScheduledDates, slotToMove].sort((a, b) => a.slotId - b.slotId));
			setScheduledDates(prevAvailabledDates => prevAvailabledDates.filter(slot => slot.slotId !== slotId));
		} 
	}

	function getDayOfWeekInPortuguese(date) {

    	const day = new Date(date).getDay();
		const daysOfWeekInPortuguese = ["Seg", "Ter", "Qua", "Qui", "Sex", "Sáb","Dom", ];
      	return daysOfWeekInPortuguese[day];
	}

	const handleChange = (event) => {
			console.log(state)
			setState({
				checkedA: false,
				checkedB: false,
				checkedC: false,
				checkedD: false,
			  });
			setState({[event.target.name]: event.target.checked });
		  

	};


	const createNewSlots = async () => {
		try {
			 await api.post(`/createslots`, {positionId:resource.positionId, userId});
		} catch (err) {
			toastError(err);
		}
		};



	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{userId
						? `${i18n.t("userModal.title.edit")}`
						: `${i18n.t("userModal.title.add")}`}
				</DialogTitle>



				<Tabs
					value={tab}
					onChange={handleTabChange}
					indicatorColor="primary"
					textColor="primary"
					variant="fullWidth"
					aria-label="full width tabs example"
					>
					<Tab className={classes.tabs} label="Usuario" value="user"/>
					{userId && <Tab className={classes.tabs} label="Recurso"value="resource" />}
					{userId && <Tab className={classes.tabs} label="Agenda"value="schedule" />}
					{userId && <Tab className={classes.tabs} label="Grelha" value="schedulerGrid" />}
					
				</Tabs>

				<TabPanel value={tab} name="user" className={classes.ticketsWrapper} index={0}>
					<Formik
						initialValues={user}
						enableReinitialize={true}
						validationSchema={UserSchema}
						onSubmit={(values, actions) => {
							setTimeout(() => {
								handleSaveUser(values);
								actions.setSubmitting(false);
							}, 400);
						}}
					>
						{({ touched, errors, isSubmitting }) => (
							<Form>
								<DialogContent dividers>
									<div className={classes.multFieldLine}>
										<Can
												role={loggedInUser.profile}
												perform="user-modal:editProfile"
												yes={() => (
													<>

													<Field
														as={TextField}
														label={i18n.t("userModal.form.id")}
														autoFocus
														name="id"
														error={touched.id && Boolean(errors.id)}
														helperText={touched.id && errors.id}
														variant="outlined"
														margin="dense"
														fullWidth
													/>
													</>
												)}
										/>
										<Field
											as={TextField}
											label={i18n.t("userModal.form.name")}
											autoFocus
											name="name"
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
										<Field
											as={TextField}
											name="password"
											variant="outlined"
											margin="dense"
											label={i18n.t("userModal.form.password")}
											error={touched.password && Boolean(errors.password)}
											helperText={touched.password && errors.password}
											type={showPassword ? 'text' : 'password'}
											InputProps={{
											endAdornment: (
												<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={() => setShowPassword((e) => !e)}
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
												</InputAdornment>
											)
											}}
											fullWidth
										/>
									</div>
									<div className={classes.multFieldLine}>
										<Field
											as={TextField}
											label={i18n.t("userModal.form.email")}
											name="email"
											error={touched.email && Boolean(errors.email)}
											helperText={touched.email && errors.email}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
										<FormControl
											variant="outlined"
											className={classes.formControl}
											margin="dense"
										>
											<Can
												role={loggedInUser.profile}
												perform="user-modal:editProfile"
												yes={() => (
													<>
														<InputLabel id="profile-selection-input-label">
															{i18n.t("userModal.form.profile.name")}
														</InputLabel>

														<Field
															as={Select}
															label={i18n.t("userModal.form.profile.name")}
															name="profile"
															labelId="profile-selection-label"
															id="profile-selection"
															required
														>
															<MenuItem value="admin">Admin</MenuItem>
															<MenuItem value="manager">Gerente</MenuItem>
															<MenuItem value="superv">Supervisor</MenuItem>
															<MenuItem value="userplus">Usuário +</MenuItem>
															<MenuItem value="user">Usuário</MenuItem>
														</Field>
													</>
												)}
											/>
										</FormControl>
									</div>
									<Can
										role={loggedInUser.profile}
										perform="user-modal:editQueues"
										yes={() => (
											<QueueSelect
												selectedQueueIds={selectedQueueIds}
												onChange={values => setSelectedQueueIds(values)}
											/>
										)}
									/>
									
									<Can
										role={loggedInUser.profile}
										perform="user-modal:editQueues"
										yes={() => (!loading &&
											<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
												<InputLabel>{i18n.t("userModal.form.whatsapp")}</InputLabel>
												<Field
													as={Select}
													value={whatsappId}
													onChange={(e) => setWhatsappId(e.target.value)}
													label={i18n.t("userModal.form.whatsapp")}
												>
													<MenuItem value={''}>&nbsp;</MenuItem>
													{whatsApps.map((whatsapp) => (
														<MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
													))}
												</Field>
											</FormControl>
										)}
									/>
								</DialogContent>
								<DialogActions>
									<Button
										onClick={handleClose}
										color="secondary"
										disabled={isSubmitting}
										variant="outlined"
									>
										Fechar
									</Button>
									<Button
										type="submit"
										color="primary"
										disabled={isSubmitting}
										variant="contained"
										className={classes.btnWrapper}
									>
										{userId
											? `Editar`
											: `Adicionar`}
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</Button>
								</DialogActions>
							</Form>
						)}
					</Formik>
				</TabPanel>

				<TabPanel value={tab} name="resource" className={classes.ticketsWrapper} index={1}>
				<Formik
					initialValues={resource}
					enableReinitialize={true}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveResource(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						
						
						<Form>
							<DialogContent dividers>
							<div className={classes.multFieldLine}>
										<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
												<InputLabel>{"Locais"}</InputLabel>
												<Field
													as={Select}
													value={placeId}
													onChange={(e) => {
															setPlaceId(e.target.value)
															setResource(() => {
																return { ...values, placeId: e.target.value };
															});
													}}
													
													label={"Local"}
												>
													<MenuItem value={''}>&nbsp;</MenuItem>
													{places.map((place) => (
														<MenuItem key={place.placeId} value={place.placeId}>{place.placeName}</MenuItem>
													))}
												</Field>
										</FormControl>
										<Field
										as={TextField}
										label={i18n.t("queueModal.form.color")}
										name="positionColor"
										id="positionColor"
										style={{minWidth:272}}
										onFocus={() => {
											setColorPickerModalOpen(true);
											//greetingRef.current.focus();
										}}
										error={touched.color && Boolean(errors.color)}
										helperText={touched.color && errors.color}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<div
														style={{ backgroundColor: values.positionColor }}
														className={classes.colorAdorment}
													></div>
												</InputAdornment>
											),
											endAdornment: (
												<IconButton
													size="small"
													color="default"
													onClick={() => setColorPickerModalOpen(true)}
												>
													<Colorize />
												</IconButton>
											),
										}}
										variant="outlined"
										margin="dense"
										
									/>
									<ColorPicker
										open={colorPickerModalOpen}
										handleClose={() => setColorPickerModalOpen(false)}
										onChange={color => {
											values.positionColor = color
											setResource(() => {
												return { ...values, positionColor:color };
											});
											
											
										}}
									/>
			

										</div>
								<div className={classes.multFieldLine}>
			


									<Field
											as={TextField}
											label={i18n.t("name.of.local")}
											name="positionName"
											variant="outlined"
											margin="dense"
											fullWidth
									/>
								</div>

							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									Fechar
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
										{userId
											? `Editar`
											: `Adicionar`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
						
					)}
				</Formik>

				</TabPanel>

				
				<TabPanel value={tab} name="schedule" className={classes.ticketsWrapper} index={2}>

					<Formik
						initialValues={{ scheduled: '', available: '' }}
						onSubmit={values => {
							console.log(values)
						}}
						>
						<>

							<Form>
							<DialogContent dividers >

							<Grid container padding={1} spacing={3} justifyContent="space-between" >
								<Grid item xs={5} style={{border: '1px solid #ccc', borderRadius:5}} >
									<Typography sx={{ fontSize: 14 }} className={classes.purple1} gutterBottom><span style={{marginTop:"10px", color:"#4a148c", fontWeight:600}}>Grade</span></Typography>
												
									<List label={i18n.t("queueModal.form.Disponiveis")} className={classes.list}>
										{scheduledDates.map(date => (
											<ListItem button onClick={() => handleScheduledDateClick(date.slotId)} key={date.slotId} style={{lineHeight:0.1}}>
												{date.slotDate} {date.slotHour}&nbsp;&nbsp;<span style={{color:"#E2252C"}}>({getDayOfWeekInPortuguese(date.slotDate)})</span> 
											</ListItem>
										))}
									</List>

								</Grid>






									<Grid item xs={2} style={{border: '0px solid #ccc', paddingLeft: 5, borderRadius:5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} >
										
										<Typography sx={{ fontSize: 14 }} className={classes.purple1} gutterBottom><span style={{marginTop:"10px", color:"#4a148c", fontWeight:600}}>Opções</span></Typography>
										<Button
											onClick={handleReset}
											color="secondary"
											variant="outlined"
										>
											Reset
										</Button>
										<hr/>
										<Typography style={{ fontSize: 10 }} className={classes.purple1} gutterBottom><span style={{marginTop:"10px", color:"#4a148c", fontWeight:(state.checkedA?900:400)}}>Todos</span></Typography>
										<PurpleSwitch size="small"  checked={state.checkedA} onChange={handleChange} name="checkedA" />
										<hr/>
										<Typography style={{ fontSize: 10 }} className={classes.purple1} gutterBottom><span style={{marginTop:"10px", color:"#4a148c",  fontWeight:(state.checkedB?900:400)}}>Todos&nbsp;do&nbsp;Dia</span></Typography>
										<PurpleSwitch size="small"  checked={state.checkedB} onChange={handleChange} name="checkedB" />
										<hr/>
										<Typography style={{ fontSize: 10 }} className={classes.purple1} gutterBottom><span style={{marginTop:"10px", color:"#4a148c",  fontWeight:(state.checkedC?900:400)}}>Todos&nbsp;da&nbsp;Manhã</span></Typography>
										<PurpleSwitch size="small"  checked={state.checkedC} onChange={handleChange} name="checkedC" />
										<hr/>
										<Typography style={{ fontSize: 10 }} className={classes.purple1} gutterBottom><span style={{marginTop:"10px", color:"#4a148c", fontWeight:(state.checkedD?900:400)}}>Todos&nbsp;da&nbsp;Tarde</span></Typography>
										<PurpleSwitch size="small"  checked={state.checkedD} onChange={handleChange} name="checkedD" />
										<hr/>
										<Button
											onClick={createNewSlots}
											color="secondary"
											variant="outlined"
										>
											Mais Datas
										</Button>
								</Grid>
								<Grid item xs={5}  style={{border: '1px solid #ccc', borderRadius:5, minWidth:200}} >
								<Typography sx={{ fontSize: 14 }} className={classes.purple1} gutterBottom><span style={{marginTop:"10px", color:"#4a148c", fontWeight:600}}>Calendário</span></Typography>
								<List label={i18n.t("queueModal.form.Calendario")} className={classes.list}>
									{availableDates.map(date  => (
											<ListItem button onClick={() => handleAvailableDateClick(date.slotId)} key={date.slotId} style={{lineHeight:0.1}}>
												{date.slotDate} {date.slotHour.split(":")[0]}:{date.slotHour.split(":")[1]}&nbsp;&nbsp;<span style={{color:"#E2252C"}}>({getDayOfWeekInPortuguese(date.slotDate)})</span> 
											</ListItem>
											
										))}
									</List>

								</Grid>
							</Grid>

							</DialogContent>
							<DialogActions>
									<Button
										onClick={handleClose}
										color="secondary"
										
										variant="outlined"
									>
										Fechar
									</Button>
									<Button
										
										color="primary"
										onClick={handleInsertAvailability}
										variant="contained"
										className={classes.btnWrapper}
									>
										{userId
											? `Salvar`
											: `Adicionar`}
										
									</Button>
								</DialogActions>
							</Form>
						</>
						</Formik>
				</TabPanel>

				<TabPanel value={tab} name="schedulerGrid" className={classes.ticketsWrapper} index={3}>
					<SchedulerGrid
						userId={user?.id}
						onClose={handleClose}
					/>
				</TabPanel>

				
			</Dialog>
		</div>
	);
};

export default UserModal;
