import React from 'react';
import Popout from './react-popout.jsx';
import AirplayIcon from '@material-ui/icons/Airplay';
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { IconButton } from "@material-ui/core";
import { purple } from '@material-ui/core/colors';

import toastError from "../../errors/toastError";

var md5 = require('md5');
export default class VideoWindow extends React.Component {

  constructor(props) {
    super(props);
    this.popout = this.popout.bind(this);
    this.incrementTimer = this.incrementTimer.bind(this);
    this.popoutClosed = this.popoutClosed.bind(this);
    this.popoutContentClicked = this.popoutContentClicked.bind(this);
    this.state = { isPoppedOut: false, timer: 0 };
	
	console.log(props)
	
	this.sessionmd5=md5(props.user.email)
	this.curruser=encodeURIComponent(props.user.name)
	this.currcontact=encodeURIComponent(props.ticket.contact.name)
	this.ticketid=props.ticket.id;
	this.naviurl=`https://video.mware.com.br:9001/video/video-host.html?sala=${this.sessionmd5}&publicRoomIdentifier=collab-${props.ticket.id}&userFullName=${this.curruser}`;
	this.naviurl2=`https://video.mware.com.br:9001/video/video-guest.html?sala=${this.sessionmd5}&publicRoomIdentifier=collab-${props.ticket.id}&userFullName=${this.currcontact}`;
	
	
  }
  
  incrementTimer() {
    let newTimer = this.state.timer + 1;
    this.setState({ timer: newTimer });
  }

  popout() {
	 const message = {
		read: 1,
		fromMe: true,
		mediaUrl: "",
		body: "Vamos fazer uma chamada de video. Por favor abra o seguinte link:\n"+this.naviurl2,
		quotedMsg: "",
	};
	try {
		api.post(`/messages/${this.ticketid}`, message);
	} catch (err) {
		toastError(err);
	}
	
	  
	
    this.setState({ isPoppedOut: true, timerId: setInterval(this.incrementTimer, 1000) });
  }

  popoutClosed() {
    if (this.state.timerId) {
      clearInterval(this.state.timerId);
      this.setState({ isPoppedOut: false, timerId: null, timer: 0 });
    }
  }

  popoutContentClicked() {
    this.popoutClosed();
  }

  render() {
    if (this.state.isPoppedOut) {
      return (
	
        <>
        <Popout title='Video' onClosing={this.popoutClosed}url={this.naviurl}></Popout>
        <span title={i18n.t("messagesList.header.buttons.video")}> 
          <IconButton	           
              size="small"
              onClick={this.popout}
              variant="contained"
            >
            <AirplayIcon style={{ color: '#152d70', fontSize: 33 }} />
          </IconButton>
        </span>
		</>
      );
    } else {
      return (
        <span title={i18n.t("messagesList.header.buttons.video")}>   
          <IconButton	           
              size="small"
              onClick={this.popout}
              variant="contained"
            >
            <AirplayIcon style={{ color: '#152d70', fontSize: 33 }} />
          </IconButton>
        </span>
      
      );
    }
  }
}
