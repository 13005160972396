import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton} from "@material-ui/core";
import { purple } from '@material-ui/core/colors';
import { Replay, Check, Assignment} from "@material-ui/icons";

import TicketTransferButton from '../TicketOptionsMenu/transferT.jxs'
import TicketDeleteButton from '../TicketOptionsMenu/deleteT.jsx'
import TicketInviteButton from "../TicketOptionsMenu/inviteT.jxs"
import ScheduledMessagesModal from "../../components/ScheduledMessagesModal";
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import BookingModal from "../../components/BookingModal";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CachedIcon from '@material-ui/icons/Cached';


import VideoWindow from '../WebRTC/video-w.jsx';
import WorkpadWindow from '../WebRTC/workpad-w.jsx';
import ScreenShareWindow from '../WebRTC/screen-w.jsx';
import TicketHistoryModal from "../TicketHistoryList";

import ExportAuditTrailReport from "../ExportAuditTrailReport"

import TimerIcon from '@material-ui/icons/Timer';
import { getBackendUrl } from "../../config"

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { id } from "date-fns/locale";



const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(1),
		},
	},
}));

const TicketActionButtons = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);

    const [ticketHistoryModalOpen, setTicketHistoryModalOpen] = useState(false);
	const [scheduledMessagesModalOpen, setScheduledMessagesModalOpen] = useState(false);
	const [bookingModalOpen, setBookingModalOpen] = useState(false);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [selectedMessageId, setSelectedMessageId] = useState(null);
	const [isHistOpen, setIsHistOpen] = useState(false);
	const [contact, setContact] = useState(0);
	const [isAuditProduced, setIsAuditProduced] = useState(false);

	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const ticketInviteMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);

    const [ticketNumber, setTicketNumber] = useState(null);


	const fileType ="application/pdf";
	const fileExtension = "pdf";
	const fileName=`ticketaudit-ref~${ticket.id}`
	const backendUrl=getBackendUrl()


	const toggleHistPopup = () => {
		setIsHistOpen(!isHistOpen);	
	  }


	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleCloseTicketInviteMenu = e => {
		setAnchorEl(null);
	};

	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);
		try {
			await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId
			});
		
			setLoading(false);
			if (status === "open") {
				history.push(`/tickets/${ticket.id}`);
			} else {
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};
	
	const handleOpenTicketHistoryModal =  async (e) => {
        console.log(e)
		setIsHistOpen(!isHistOpen);
    	setTicketNumber(ticket.id)
		setTicketHistoryModalOpen(true);
		// handleClose();
	};

	const handleCloseScheduledMessagesModal = () => {
		setSelectedMessageId(null);
		setSelectedContactId(null);
		setScheduledMessagesModalOpen(false);
	};
	  
	const handleCloseBookingModal = () => {
		setSelectedMessageId(null);
		setSelectedContactId(null);
		setBookingModalOpen(false);
	};

	const handleNewScheduledMessage  =  async (e, userid, ticketid, contactid)  => {

		console.log("handleNewScheduledMessage", userid, ticketid, contactid)
		
		setContact(contactid)
		setSelectedContactId(contactid);
		setTicketNumber(ticketid)
		setScheduledMessagesModalOpen(true)
	}

	const handleNewBooking  =  async (e, userid, ticketid, contactid)  => {

		console.log("handleNewBooking", userid, ticketid, contactid)
		
		setContact(contactid)
		setSelectedContactId(contactid);
		setTicketNumber(ticketid)
		setBookingModalOpen(true)
	}

	const handleAuditReport = async() => {
		       
			console.log("handleAuditReport", ticket.id)

			try {
				const { data } = await api.get(`/tickets-export-report?ticketId=${ticket.id}`);   
				if(data){
					setIsAuditProduced(true)
				}
				
			} catch (err) {
			   console.log(err)
			}
	}


	return (
		<div className={classes.actionButtons}>


			<ScheduledMessagesModal
					user={user}
					open={scheduledMessagesModalOpen}
					onClose={handleCloseScheduledMessagesModal}
					messageId={selectedMessageId}
					contactId={selectedContactId}
					ticketId = {ticketNumber}
					/>
			<BookingModal
					user={user}
					open={bookingModalOpen}
					onClose={handleCloseBookingModal}
					messageId={selectedMessageId}
					contactId={selectedContactId}
					ticketId = {ticketNumber}
					/>

			{ticket.status === "closed" && (
				<ButtonWithSpinner
					loading={loading}
					startIcon={<Replay />}
					size="small"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.reopen")}
				</ButtonWithSpinner>
			)}
			{ticket.status === "open" && (
				<>						
					
						<TicketTransferButton
							ticket={ticket}
							anchorEl={anchorEl}
							menuOpen={ticketOptionsMenuOpen}
							handleClose={handleCloseTicketOptionsMenu}

						/>

						<TicketInviteButton
							ticket={ticket}
							anchorEl={anchorEl}
							menuOpen={ticketInviteMenuOpen}
							handleClose={handleCloseTicketInviteMenu}

						/>

						<span title={i18n.t("mainDrawer.listItems.scheduledMessages")}> 	
                            <IconButton
                                loading={loading}
                                variant="contained"
                                size="small"
                                onClick={e => handleNewScheduledMessage(e, user?.id, ticket?.id, ticket.contact?.id)}
                            >
                            <TimerIcon style={{ color: '#152d70', fontSize: 33 }} />
                            </IconButton>
                        </span>

						
						<span title={i18n.t("messagesList.header.buttons.task")}> 	
                            <IconButton
                                loading={loading}
                                variant="contained"
                                size="small"
                                onClick={e => handleNewBooking(e, user?.id, ticket?.id, ticket.contact?.id)}
                            >
                            <EventAvailableIcon style={{ color: '#152d70', fontSize: 33 }} />
                            </IconButton>
                        </span>
				

		
                        <span title={i18n.t("messagesList.header.buttons.return")}> 	
                            <IconButton
                                loading={loading}
                                variant="contained"
                                size="small"
                                onClick={e => handleUpdateTicketStatus(e, "pending", null)}
                            >
                            <Replay style={{ color: '#152d70', fontSize: 33 }} />
                            </IconButton>
                        </span>

                    

					
                        <span title={i18n.t("messagesList.header.buttons.resolve")}> 
                            <IconButton
                                loading={loading}
                                size="small"
                                variant="contained"
                                onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
                            >
                                <Check  style={{ color: '#152d70', fontSize: 33 }}/>
								
								
                            </IconButton>
                        </span>


                        <span title={i18n.t("messagesList.header.buttons.delete")}> 
                            <TicketDeleteButton
                                ticket={ticket}
                                anchorEl={anchorEl}
                                menuOpen={ticketOptionsMenuOpen}
                                handleClose={handleCloseTicketOptionsMenu}
                            />
                        </span>

						<span title={i18n.t("messagesList.header.buttons.history")}> 
							<IconButton
								size="small"
								variant="contained"
								ticket={ticket}
								anchorEl={anchorEl}
								menuOpen={ticketOptionsMenuOpen}
								onClick={handleOpenTicketHistoryModal}
							>
								<Assignment  style={{ color: '#152d70', fontSize: 33 }}/>
							</IconButton>
						</span>

						{isAuditProduced === false && (
						<span title={i18n.t("messagesList.header.buttons.produce")}> 
							<IconButton
								size="small"
								variant="contained"
								anchorEl={anchorEl}
								onClick={handleAuditReport}
							>
								<CachedIcon  style={{ color: '#152d70', fontSize: 33 }}/>
							</IconButton>
						</span>
						)}
						

						{isAuditProduced === true && (
							<span title={i18n.t("messagesList.header.buttons.download")}> 
								<IconButton
									size="small"
									variant="contained"
									anchorEl={anchorEl}
									target="_blank"
              						href={`${backendUrl}/public/`+fileName+"."+fileExtension}
								>
									<CloudDownloadIcon  style={{ color: '#152d70', fontSize: 33 }}/>
								</IconButton>
							</span>
						)}







                    

  
						<VideoWindow 
							user = {user}
							ticket = {ticket}
							/>
							

				</>
			)}
			{ticket.status === "pending" && (
				<ButtonWithSpinner
					loading={loading}
					size="small"
					variant="contained"
					color="primary"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.accept")}
				</ButtonWithSpinner>
			)}

            {isHistOpen && <TicketHistoryModal className={classes.popup}
				modalOpen={ticketHistoryModalOpen}
				ticketId={ticket.id}
				handleClose={toggleHistPopup}
			/>}
		</div>
	);
};

export default TicketActionButtons;
